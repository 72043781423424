import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Property, Location } from './app.models';
import { AppSettings } from './app.settings';
import {AngularFirestore} from "@angular/fire/firestore";
import {AngularFireStorage, AngularFireUploadTask} from "angularfire2/storage";
import {User} from "./core/user";
import {DatePipe} from "@angular/common";

export class Data {
  constructor(public properties: Property[],
              public compareList: Property[],
              public favorites: Property[],
              public locations: Location[]) { }
}

@Injectable({
  providedIn: 'root'
})
export class AppService {
  public Data = new Data(
    [], // properties
    [], // compareList
    [], // favorites
    []  // locations
  );

  task: AngularFireUploadTask;

  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadURL;

  public url = "assets/data/";
  public apiKey = 'AIzaSyAir4tXhx3X-wcdZnhe8TLlo9J2m_AKx6w';

  public isUpdated: boolean = false;
  
  constructor(public http:HttpClient,
              private storage: AngularFireStorage,
              private bottomSheet: MatBottomSheet, 
              private snackBar: MatSnackBar,
              public appSettings:AppSettings,
              private firestore: AngularFirestore,
              public datePipe: DatePipe,) { }
    
  public getProperties(): Observable<Property[]>{
    return this.http.get<Property[]>(this.url + 'properties.json');
  }

  public createProperty(property: Property){
    this.firestore.collection('propList').add({...property}).then(res => {
    }).catch(err => console.log(err)
    ).finally(() => {
      console.warn("Property Added");
    });
  }

  public updateProperty(property: Property, uid:string){
    this.isUpdated = false;
    console.log(property);
    this.firestore.collection('propList').doc(uid).set({...property})
        .then(res => {
          this.isUpdated = true;
          console.warn("Property Updated");
          this.snackBar.open('The property "' + property.id + '" has been updated.', '×', {
            verticalPosition: 'top',
            panelClass: ['if-success'],
            duration: 5000
          });
        }).catch(err => {
        console.log(err);
       });
  }
  public createUser(user){
    this.firestore.collection<User>(`users/${user.uid}`).add(user).then(res => {
    }).catch(err => console.log(err)
    ).finally(() => {
      this.snackBar.open("Thank you! We will contact you soon.", '×', {
        verticalPosition: 'bottom',
        panelClass: ['if-success'],
        duration: 5000
      });
    });
  }

  public addContactData(data){
    this.firestore.collection('contacts').add(data).then(res => {
    }).catch(err => console.log(err)
    ).finally(() => {
      this.snackBar.open("Thank you! We will contact you soon.", '×', {
        verticalPosition: 'bottom',
        panelClass: ['if-updating'],
        duration: 5000
      });
    });
  }

  public getUser(uid){
    return this.firestore.doc<User>(`users/${uid}`).valueChanges();
  }

  public uploadFile(file: File){
    const path = `test/${Date.now()}_${file.name}`;
    const ref = this.storage.ref(path);
    // The main task
    this.storage.upload(path, file).snapshotChanges().pipe(
        finalize( () =>  {
          ref.getDownloadURL().subscribe(url => {
            console.log(url);
            this.firestore.collection('files').add( { downloadURL: url, path });
          });
        }),
    ).subscribe();

  }

  public getMyProperties(): Observable<Property[]> {
    // @ts-ignore
    return this.firestore.collection('propList').valueChanges({idField: 'docId'});
  }

  /**
   * generate groups of 4 random characters
   * @example getUniqueId(1) : 607f
   * @example getUniqueId(2) : 95ca-361a-f8a1-1e73
   */
  public getUniqueId(parts: number): string {
    const stringArr = [];
    for(let i = 0; i< parts; i++){
      // tslint:disable-next-line:no-bitwise
      const S4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      stringArr.push(S4);
    }
    return stringArr.join('-');
  }

  public getPropertyById(id): Observable<Property>{

    // @ts-ignore
    return this.firestore.collection('propList', ref => ref.where('id', '==', id));

    //return this.firestore.collection('propList').doc(id).get();

    //return this.http.get<Property>(this.url + 'property-' + id + '.json');
  }

  public getFeaturedProperties(): Observable<Property[]>{
    return this.http.get<Property[]>(this.url + 'featured-properties.json');
  } 

  public getRelatedProperties(): Observable<Property[]>{
    return this.http.get<Property[]>(this.url + 'related-properties.json');
  }

  public getPropertiesByAgentId(agentId): Observable<Property[]>{
    return this.http.get<Property[]>(this.url + 'properties-agentid-' + agentId + '.json');
  }

  public getAddress(lat = 40.714224, lng = -73.961452){ 
    return this.http.get('https://maps.googleapis.com/maps/api/geocode/json?latlng='+lat+','+lng+'&key='+this.apiKey);
  }

  public addToCompare(property:Property, component, direction){ 
    if(!this.Data.compareList.filter(item=>item.id == property.id)[0]){
      this.Data.compareList.push(property);
      this.bottomSheet.open(component, {
        direction: direction
      }).afterDismissed().subscribe(isRedirect=>{  
        if(isRedirect){
          window.scrollTo(0,0);
        }        
      }); 
    } 
  }

  public addToFavorites(property:Property, direction){
    if(!this.Data.favorites.filter(item=>item.id == property.id)[0]){
      this.Data.favorites.push(property);
      this.snackBar.open('The property "' + property.title + '" has been added to favorites.', '×', {
        verticalPosition: 'top',
        duration: 3000,
        direction: direction 
      });  
    }    
  }

  public getPropertyTypes(){
    return [ 
      { id: 1, name: 'Office' },
      { id: 2, name: 'House' },
      { id: 3, name: 'Apartment' }
    ]
  }

  public getPropertyStatuses(){
    return [ 
      { id: 2, name: 'For Rent' },
      { id: 3, name: 'Rented' },
    ]
  }

  public getCities(){
    return [ 
      { id: 1, name: 'Stuttgart' },
    ]
  }

  public getNeighborhoods(){
    return [      
      { id: 1, name: 'Astoria', cityId: 1 },
      { id: 2, name: 'Midtown', cityId: 1 },
      { id: 3, name: 'Chinatown', cityId: 1 }, 
      { id: 4, name: 'Austin', cityId: 2 },
      { id: 5, name: 'Englewood', cityId: 2 },
      { id: 6, name: 'Riverdale', cityId: 2 },      
      { id: 7, name: 'Hollywood', cityId: 3 },
      { id: 8, name: 'Sherman Oaks', cityId: 3 },
      { id: 9, name: 'Highland Park', cityId: 3 },
      { id: 10, name: 'Belltown', cityId: 4 },
      { id: 11, name: 'Queen Anne', cityId: 4 },
      { id: 12, name: 'Green Lake', cityId: 4 }      
    ]
  }

  public getStreets(){
    return [      
      { id: 1, name: 'Immenhofer Straße', cityId: 1, neighborhoodId: 1},
    ]
  }

  public getFeatures(){
    return [ 
      { id: 1, name: 'Air Conditioning', selected: true },
      { id: 2, name: 'High speed internet wifi with personal connection', selected: true },
      { id: 3, name: 'Cable TV and Smart TV', selected: true },
      { id: 4, name: 'Work place with Desk Lamp', selected: true },
      { id: 5, name: 'Central heating Hot water for accomodation', selected: true },
      { id: 6, name: 'Fully equipped kitchen with dining area', selected: true },
      { id: 7, name: 'Cubical shower or bath tub', selected: true },
      { id: 8, name: 'Iron with ironing board', selected: true },
      { id: 9, name: 'Towel, soap and toilet paper', selected: true },
      { id: 10, name: 'Washing machine and dryer', selected: true },
    ]
  }

  public getKitchens(){
    return [
      { id: 1, name: 'coffee machine', selected: true },
      { id: 2, name: 'Basic equipment for cooking', selected: true },
      { id: 3, name: 'Pots and pans, oil, salt and pepper', selected: true },
      { id: 4, name: 'Dishes and cutlery', selected: true},
      { id: 5, name: 'Dishwasher', selected: true},
      { id: 6, name: 'Microwave', selected: true},
      { id: 7, name: 'Fridge', selected: true},
      { id: 8, name: 'Oven and Stove', selected: true},
    ]
  }


  public getFamily(){
    return [
      { id: 1, name: 'Baby bed or cot', selected: true },
      { id: 2, name: 'Window shutter in all rooms', selected: true },
      { id: 3, name: 'Safety sockets for children', selected: true },
      { id: 4, name: 'Common dining area', selected: true},
      { id: 5, name: 'Kinder garden and children play area within 500 meters', selected: true},
    ]
  }

  public getBedAndBath(){
    return [
      { id: 1, name: 'King or Queen size box spring bed', selected: true },
      { id: 2, name: 'Additional beds possible on request', selected: true },
      { id: 3, name: 'Bed covers', selected: true },
      { id: 4, name: 'Shampoo', selected: true},
      { id: 5, name: 'hangers', selected: true},
      { id: 6, name: 'hairdryer', selected: true},

    ]
  }

  public getLogistics(){
    return [
      { id: 1, name: 'Luggage can be stored', selected: true },
      { id: 2, name: 'Guests can arrive early or late', selected: true },
      { id: 3, name: 'Long-term stay possible', selected: true },
      { id: 4, name: 'Guests can stay 28 days or more', selected: true},
    ]
  }

  public getOutdoor(){
    return [
      { id: 1, name: 'Terrace', selected: true },
      { id: 2, name: 'Balcony', selected: true },
      { id: 3, name: 'Parking', selected: false },

    ]
  }

  public getSecurity(){
    return [
      { id: 1, name: 'Smoke detector', selected: true },
      { id: 2, name: 'Medical emergency helpline', selected: true },
    ]
  }


  public getHomeCarouselSlides(){
    return this.http.get<any[]>(this.url + 'slides.json');
  }


  public filterData(data, params: any, sort?, page?, perPage?){
    if(params){

/*      if(params.propertyType){
        data = data.filter(property => property.propertyType == params.propertyType.name)
      }*/

      if(params.propertyStatus && params.propertyStatus.length){       
        let statuses = [];
        params.propertyStatus.forEach(status => { statuses.push(status.name) });           
        let properties = [];
        data.filter(property =>{
            if(statuses.indexOf(property.propertyStatus) > -1){
              if(!properties.includes(property)){
                properties.push(property);
              }                
            }
          }
        );
        data = properties;
      }

      if(params.price){
        /*if(this.appSettings.settings.currency == 'USD'){
          if(params.price.from){
            data = data.filter(property => {
              if(property.priceDollar.sale && property.priceDollar.sale >= params.price.from ){
                return true;
              }
              if(property.priceDollar.rent && property.priceDollar.rent >= params.price.from ){
                return true;
              } 
              return false;
            });
          }
          if(params.price.to){
            data = data.filter(property => {
              if(property.priceDollar.sale && property.priceDollar.sale <= params.price.to){
                return true;
              }
              if(property.priceDollar.rent && property.priceDollar.rent <= params.price.to){
                return true;
              } 
              return false;
            });          
          }
        }*/
        if(this.appSettings.settings.currency == 'EUR'){
          if(params.price.from){
            data = data.filter(property => {
              if (property.priceEuroM && property.priceEuroM >= params.price.from){
                return true;
              }
              return false;

              /*if(property.priceEuro.sale && property.priceEuro.sale >= params.price.from ){
                return true;
              }
              if(property.priceEuro.rent && property.priceEuro.rent >= params.price.from ){
                return true;
              } */
            });

          }
          if(params.price.to){
            data = data.filter(property => {
              if (property.priceEuroM && property.priceEuroM <= params.price.to){
                return true;
              }
              return false;
              /*if(property.priceEuro.sale && property.priceEuro.sale <= params.price.to){
                return true;
              }
              if(property.priceEuro.rent && property.priceEuro.rent <= params.price.to){
                return true;
              } */
            });
          }
        }        
      }  
/*
      if(params.city){
        data = data.filter(property => property.city == params.city.name)
      }

      if(params.zipCode){
        data = data.filter(property => property.zipCode == params.zipCode)
      }
      
      if(params.neighborhood && params.neighborhood.length){       
        let neighborhoods = [];
        params.neighborhood.forEach(item => { neighborhoods.push(item.name) });           
        let properties = [];
        data.filter(property =>
          property.neighborhood.forEach(item => {             
            if(neighborhoods.indexOf(item) > -1){                 
              if(!properties.includes(property)){
                properties.push(property);
              }                
            }
          })
        );
        data = properties;
      }*/

      if(params.street && params.street.length){       
        let streets = [];
        params.street.forEach(item => { streets.push(item.name) });           
        let properties = [];
        data.filter(property =>{
            if(streets.indexOf(property.street) > -1){
              if(!properties.includes(property)){
                properties.push(property);
              }                
            }
          }
        );
        data = properties;
      }

      if(params.bedrooms){
        if(params.bedrooms.from){
          data = data.filter(property => property.bedrooms >= params.bedrooms.from)
        }
        if(params.bedrooms.to){
          data = data.filter(property => property.bedrooms <= params.bedrooms.to)
        }
      }

      if(params.dateFrom || params.dateUntil){
        if(params.dateFrom){
          data = data.filter(property => {
            if (Date.parse(property.availableDateFrom) <= Date.parse(this.datePipe.transform(params.dateFrom.toISOString().toLocaleString(),'yyyy-MM-dd'))){
              return true;
            }
            return false;
          });
        }
        if(params.dateUntil){
          data = data.filter(property => {
            if ( Date.parse(property.bookedDateUntil) >= Date.parse(this.datePipe.transform(params.dateUntil.toISOString().toLocaleString(),'yyyy-MM-dd'))){
              return true;
            }
            return false;
          });
        }
      }
      
     /* if(params.bathrooms){
        if(params.bathrooms.from){
          data = data.filter(property => property.bathrooms >= params.bathrooms.from)
        }
        if(params.bathrooms.to){
          data = data.filter(property => property.bathrooms <= params.bathrooms.to)
        }
      } 

      if(params.garages){
        if(params.garages.from){
          data = data.filter(property => property.garages >= params.garages.from)
        }
        if(params.garages.to){
          data = data.filter(property => property.garages <= params.garages.to)
        }
      } 

      if(params.area){
        if(params.area.from){
          data = data.filter(property => property.area.value >= params.area.from)
        }
        if(params.area.to){
          data = data.filter(property => property.area.value <= params.area.to)
        }
      } 

      if(params.yearBuilt){
        if(params.yearBuilt.from){
          data = data.filter(property => property.yearBuilt >= params.yearBuilt.from)
        }
        if(params.yearBuilt.to){
          data = data.filter(property => property.yearBuilt <= params.yearBuilt.to)
        }
      }
*/
/*
      if(params.features){
        let arr = [];
        params.features.forEach(feature => { 
          if(feature.selected)
            arr.push(feature.name);
        });  
        if(arr.length > 0){
          let properties = [];
          data.filter(property =>
            property.features.forEach(feature => {             
              if(arr.indexOf(feature) > -1){                 
                if(!properties.includes(property)){
                  properties.push(property);
                }                
              }
            })
          );
          data = properties;
        }         
        
      }
*/

    }

    // console.log(data)
     
    this.sortData(sort, data);
    return this.paginator(data, page, perPage)
  }

  public sortData(sort, data){
    if(sort){
      switch (sort) {
        case 'Newest':
          data = data.sort((a, b)=> {return <any>new Date(b.published) - <any>new Date(a.published)});           
          break;
        case 'Oldest':
          data = data.sort((a, b)=> {return <any>new Date(a.published) - <any>new Date(b.published)});           
          break;
        case 'Popular':
          data = data.sort((a, b) => { 
            if(a.ratingsValue/a.ratingsCount < b.ratingsValue/b.ratingsCount){
              return 1;
            }
            if(a.ratingsValue/a.ratingsCount > b.ratingsValue/b.ratingsCount){
              return -1;
            }
            return 0; 
          });
          break;
        case 'Price (Low to High)':
          if(this.appSettings.settings.currency == 'USD'){
            data = data.sort((a,b) => {
              if((a.priceDollar.sale || a.priceDollar.rent) > (b.priceDollar.sale || b.priceDollar.rent)){
                return 1;
              }
              if((a.priceDollar.sale || a.priceDollar.rent) < (b.priceDollar.sale || b.priceDollar.rent)){
                return -1;
              }
              return 0;  
            }) 
          }
          if(this.appSettings.settings.currency == 'EUR'){
            data = data.sort((a,b) => {
              if((a.priceEuro.sale || a.priceEuro.rent) > (b.priceEuro.sale || b.v.rent)){
                return 1;
              }
              if((a.priceEuro.sale || a.priceEuro.rent) < (b.priceEuro.sale || b.priceEuro.rent)){
                return -1;
              }
              return 0;  
            }) 
          }
          break;
        case 'Price (High to Low)':
          if(this.appSettings.settings.currency == 'USD'){
            data = data.sort((a,b) => {
              if((a.priceDollar.sale || a.priceDollar.rent) < (b.priceDollar.sale || b.priceDollar.rent)){
                return 1;
              }
              if((a.priceDollar.sale || a.priceDollar.rent) > (b.priceDollar.sale || b.priceDollar.rent)){
                return -1;
              }
              return 0;  
            }) 
          }
          if(this.appSettings.settings.currency == 'EUR'){
            data = data.sort((a,b) => {
              if((a.priceEuro.sale || a.priceEuro.rent) < (b.priceEuro.sale || b.v.rent)){
                return 1;
              }
              if((a.priceEuro.sale || a.priceEuro.rent) > (b.priceEuro.sale || b.priceEuro.rent)){
                return -1;
              }
              return 0;  
            }) 
          }
          break;
        default:
          break;
      }
    }
    return data;
  }

  public paginator(items, page?, perPage?) { 
    var page = page || 1,
    perPage = perPage || 4,
    offset = (page - 1) * perPage,   
    paginatedItems = items.slice(offset).slice(0, perPage),
    totalPages = Math.ceil(items.length / perPage);
    return {
      data: paginatedItems,
      pagination:{
        page: page,
        perPage: perPage,
        prePage: page - 1 ? page - 1 : null,
        nextPage: (totalPages > page) ? page + 1 : null,
        total: items.length,
        totalPages: totalPages,
      }
    };
  }



  public getTestimonials(){
    return [
        { 
            text: "Vincent and Julia are great landlords. I got immediate support for any\n" +
                "question at any time of the day or night. It felt like a cross between a caring family and\n" +
                "concierge service.",
            author: 'Mr. Brad Thomson',
            position: 'American',
            image: 'assets/images/profile/adam.jpg' 
        },
        { 
            text: 'The location of Vincent and Julia house is perfect. Very central, everything\n' +
                'within walking distance, right next to the Shopping Gerber and next to the public transport\n' +
                'network. You just don&#39;t need a car here!',
            author: 'Jane Smith',
            position: 'American',
            image: 'assets/images/profile/ashley.jpg' 
        },
        { 
            text: 'We love to cook. We cook fresh food three times a day. Vincent and \n' +
                'Julia apartments are perfectly equipped, especially in the kitchen there is nothing missing.\n' +
                'It should also be pointed out that the house is beautiful and the location is gigantic. Best\n' +
                'rental apartment yet!',
            author: 'Kumarendram Singh',
            position: 'Indian',
            image: 'assets/images/profile/bruno.jpg' 
        },
        { 
            text: 'Like all Americans, we love to move freely and feel the vibe. The house is\n' +
                'just right for that! Vincent and Julia are great hosts, we felt like family and cared for!',
            author: 'Matthew Pierce',
            position: 'American',
            image: 'assets/images/profile/julia.jpg' 
        }
    ];
  }

  public getAgents(){
    return [        
        { 
            id: 1,
            fullName: 'Lusia Manuel',
            desc: 'Phasellus sed metus leo. Donec laoreet, lacus ut suscipit convallis, erat enim eleifend nulla, at sagittis enim urna et lacus.',            
            organization: 'HouseKey',
            email: 'lusia.m@housekey.com',
            phone: '(224) 267-1346',
            social: {
              facebook: 'lusia',
              twitter: 'lusia',
              linkedin: 'lusia',
              instagram: 'lusia',
              website: 'https://lusia.manuel.com'
            },
            ratingsCount: 6,
            ratingsValue: 480,
            image: 'assets/images/agents/a-1.jpg' 
        },
        { 
            id: 2,
            fullName: 'Andy Warhol',
            desc: 'Phasellus sed metus leo. Donec laoreet, lacus ut suscipit convallis, erat enim eleifend nulla, at sagittis enim urna et lacus.',            
            organization: 'HouseKey',
            email: 'andy.w@housekey.com',
            phone: '(212) 457-2308',
            social: {
              facebook: '',
              twitter: '',
              linkedin: '',
              instagram: '',
              website: 'https://andy.warhol.com'
            },
            ratingsCount: 4,
            ratingsValue: 400,
            image: 'assets/images/agents/a-2.jpg' 
        },        
        { 
            id: 3,
            fullName: 'Tereza Stiles',
            desc: 'Phasellus sed metus leo. Donec laoreet, lacus ut suscipit convallis, erat enim eleifend nulla, at sagittis enim urna et lacus.',            
            organization: 'HouseKey',
            email: 'tereza.s@housekey.com',
            phone: '(214) 617-2614',
            social: {
              facebook: '',
              twitter: '',
              linkedin: '',
              instagram: '',
              website: 'https://tereza.stiles.com'
            },
            ratingsCount: 4,
            ratingsValue: 380,
            image: 'assets/images/agents/a-3.jpg' 
        },
        { 
          id: 4,
          fullName: 'Michael Blair',
          desc: 'Phasellus sed metus leo. Donec laoreet, lacus ut suscipit convallis, erat enim eleifend nulla, at sagittis enim urna et lacus.',            
          organization: 'HouseKey',
          email: 'michael.b@housekey.com',
          phone: '(267) 388-1637',
          social: {
            facebook: '',
            twitter: '',
            linkedin: '',
            instagram: '',
            website: 'https://michael.blair.com'
          },
          ratingsCount: 6,
          ratingsValue: 480,
          image: 'assets/images/agents/a-4.jpg'  
        },
        { 
            id: 5,
            fullName: 'Michelle Ormond',
            desc: 'Phasellus sed metus leo. Donec laoreet, lacus ut suscipit convallis, erat enim eleifend nulla, at sagittis enim urna et lacus.',            
            organization: 'HouseKey',
            email: 'michelle.o@housekey.com',
            phone: '(267) 388-1637',
            social: {
              facebook: '',
              twitter: '',
              linkedin: '',
              instagram: '',
              website: 'https://michelle.ormond.com'
            },
            ratingsCount: 6,
            ratingsValue: 480, 
            image: 'assets/images/agents/a-5.jpg' 
        }
    ];
  }



  public getClients(){
    return [  
        { name: 'aloha', image: 'assets/images/clients/aloha.png' },
        { name: 'dream', image: 'assets/images/clients/dream.png' },  
        { name: 'congrats', image: 'assets/images/clients/congrats.png' },
        { name: 'best', image: 'assets/images/clients/best.png' },
        { name: 'original', image: 'assets/images/clients/original.png' },
        { name: 'retro', image: 'assets/images/clients/retro.png' },
        { name: 'king', image: 'assets/images/clients/king.png' },
        { name: 'love', image: 'assets/images/clients/love.png' },
        { name: 'the', image: 'assets/images/clients/the.png' },
        { name: 'easter', image: 'assets/images/clients/easter.png' },
        { name: 'with', image: 'assets/images/clients/with.png' },
        { name: 'special', image: 'assets/images/clients/special.png' },
        { name: 'bravo', image: 'assets/images/clients/bravo.png' }
    ];
  }


}
