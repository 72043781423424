export class Property {
    constructor(public id: string,
                public title: string,
                public desc: string,
                public offers: string,
                public propertyType: string,
                public propertyStatus: string,
                public city: string,
                public zipCode: string,
                public street: string,
                public apartmentNumber: string,
                public formattedAddress: string,
                public features: string[],
                public kitchenFeatures: string[],
                public familyFeatures: string[],
                public bedAndBathFeatures: string[],
                public securityFeatures: string[],
                public logisticsFeatures: string[],
                public outdoorFeatures: string[],
                public featured: boolean,
                public priceEuroM: string,
                public priceEuroD: string,
                public priceEuroW: string,
                public bedrooms: number,
                public livingRooms: number,
                public workingRooms: number,
                public kitchens: number,
                public bathrooms: number,
                public guestBathrooms: number,
                public area: string,
                public availableDateFrom: string,
                public bookedDateUntil: string,
                public yearBuilt: number,
                public ratingsCount: number,
                public ratingsValue: number,
                public additionalFeatures: AdditionalFeature[],
                public gallery: Gallery[],
                public plans: Plan[],
                public videos: Video[],
                public published: string,
                public lastUpdate: string,
                public views: number){ }
}

export class Area {
    constructor(public id: string,
                public value: number,
                public unit: string){ }
}

export class AdditionalFeature {
    constructor(public id: string,
                public name: string,
                public value: string){ }
}

export class Location {
    constructor(public id: string,
                public lat: number,
                public lng: number){ }
}

export class Price {
    public sale: number;
    public rent: number;
}


export class Gallery {
    constructor(public id: string,
                public small: string,
                public medium: string,
                public big: string){ }
}

export class Plan {
    constructor(public id: string,
                public name: string,
                public desc: string,
                public area: Area,
                public rooms: number,
                public baths: number,
                public image: string){ }
}

export class Video {
    constructor(public id: string,
                public name: string,
                public link: string){ }
}

export class Pagination {
    constructor(public page: number,
                public perPage: number,
                public prePage: number,
                public nextPage: number,
                public total: number,
                public totalPages: number){ }
}

