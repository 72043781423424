import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFirestore} from '@angular/fire/firestore';
import {Router} from '@angular/router';
import {ThrowStmt} from '@angular/compiler';
import {BehaviorSubject, observable, Observable, of} from 'rxjs';
import {User} from "./user";
import {switchMap, map} from "rxjs/operators";
import {AppService} from "../app.service";
import {MatSnackBar} from "@angular/material/snack-bar";

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private eventAuthError = new BehaviorSubject<string>("");
    eventAuthError$ = this.eventAuthError.asObservable();

    newUser: any;
    isLogin: boolean = false;
    isAuthenticated: Observable<boolean>= this.afAuth.authState.pipe(
        switchMap(authState => {
                if (!authState) {
                    return of(false);
                } else {
                    return of(true);
                }

            }
        )
    );
    user$: Observable<User> = this.afAuth.authState.pipe(
        switchMap(
            user => {
                if (user) {
                    return this.appService.getUser(user.uid);
                } else {
                    return of(null);
                }
            })
    );


    constructor(
        private afAuth: AngularFireAuth,
        private db: AngularFirestore,
        private router: Router,
        private snackBar: MatSnackBar,
        private appService: AppService) {
    }

    getUserState() {
        return this.afAuth.authState;
    }

    login(email: string, password: string) {
        this.afAuth.auth.signInWithEmailAndPassword(email, password)
            .catch(error => {
                this.snackBar.open('username or password is wrong', '×', {
                    verticalPosition: 'bottom',
                    panelClass: ['notif-success'],
                    duration: 5000
                });
                this.eventAuthError.next(error);
            })
            .then(userCredential => {
                if (userCredential) {
                    this.router.navigate(['/']);
                }
            })
    }

    createUser(user) {
        this.afAuth.auth.createUserWithEmailAndPassword(user.email, user.password)
            .then(userCredential => {
                this.newUser = user;
                console.log(userCredential);
                userCredential.user.updateProfile({
                    displayName: user.firstName + ' ' + user.lastName
                });

                this.insertUserData(userCredential)
                    .then(() => {
                        this.router.navigate(['/home']);
                    });
            })
            .catch(error => {
                this.eventAuthError.next(error);
            });
    }

    insertUserData(userCredential: firebase.auth.UserCredential) {
        return this.db.doc(`Users/${userCredential.user.uid}`).set({
            email: this.newUser.email,
            firstname: this.newUser.firstName,
            lastname: this.newUser.lastName,
            role: 'network user'
        })
    }

    logout() {
        this.isAuthenticated = of(false);
        this.afAuth.auth.signOut();
        this.router.navigate(['/login']);
    }

    ///// Role-based Authorization //////

    canRead(user: User): boolean {
        // const allowed = ['admin', 'editor', 'subscriber']
        // return this.checkAuthorization(user, allowed)

        return true;
    }

    canEdit(user: User): boolean {
        const allowed = ['admin', 'editor']
        return this.checkAuthorization(user, allowed)
    }

    canDelete(user: User): boolean {
        const allowed = ['admin']
        return this.checkAuthorization(user, allowed)
    }

    // determines if user has matching role
    private checkAuthorization(user: User, allowedRoles: string[]): boolean {
        if (!user) return false;
        for (const role of allowedRoles) {
            if (user.roles[role]) {
                return true
            }
        }
        return false
    }
}
